import "./profile.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
  getDocs,
  where,
  query
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const UpdateProfile = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate()
  const { productId } = useParams();

  const auth = getAuth();

  const { currentUser } = useContext(AuthContext);

  const uid = currentUser?.uid

  const [userDetails, setUserDetails] = useState(null);
  const [userImage, setUserImage] = useState(null);
  
  const handleDash = (bname) => {
    if (bname) {
      navigate("/", { state: { bname: bname } });
    } else {
      // Handle the case when bname is undefined
      console.error("bname is undefined");
    }
  };   

  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountryByCoordinates(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  // const fetchCountry = () => {
  //   fetch("http://ip-api.com/json/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('Fetched country data:', data); // Log the fetched country data
  //       const countryName = data.country || "";
  //       setUserCountry(countryName);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting user's country:", error);
  //     });
  // };  

  const fetchCountryByCoordinates = (latitude, longitude) => {
    // You can use reverse geocoding API here to fetch country based on latitude and longitude
    // Example: Fetch country using Google Maps Geocoding API
    const apiKey = 'AIzaSyAUlvtxGpDfZvmYpvt02uwn8EunzwgSUk8';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Extract country from the response
        const country = data.results[0].address_components.find(
          (component) => component.types.includes("country")
        );
        const countryName = country ? country.long_name : "";
        // console.log(countryName)
        setUserCountry(countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country by coordinates:", error);
      });
  };

  const [euroToDollarRate, setEuroToDollarRate] = useState(null);

  useEffect(() => {
    // Fetch exchange rate from euros to dollars
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch("https://api.exchangerate-api.com/v4/latest/GBP");
        const data = await response.json();
        const exchangeRate = data.rates.USD;
        setEuroToDollarRate(exchangeRate);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  // Function to convert euros to dollars
  const convertEuroToDollar = (amount) => {
    if (euroToDollarRate) {
      return (amount * euroToDollarRate).toFixed(2);
    } else {
      return amount; // Return original amount if exchange rate is not available yet
    }
  };

  const fetchUserDetails = async () => {
    try {
      if (uid) {
        // Check if the uid is the special one
        if (uid === "QuYLksMLriOhMtUcYo7TGCNrRUh1") {
          setUserDetails({
            name: "Groce Admin",
            email: "groce@admin",
          });
        } else {
          // Fetch details from the database
          const userDoc = await getDoc(doc(db, "users", uid));
          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserDetails(userData);
  
            // Check if the user has an image
            if (userData?.image) {
              setUserImage(userData.image);
            } else {
              setUserImage(null);
            }
  
            // Autofill the input fields with existing data
            setData(userData);
          } else {
            console.log("User not found in the database");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };  
  
  // Call the function when the component mounts or when the currentUser changes
  useEffect(() => {
    fetchUserDetails();
  }, [uid]);  

  useEffect(() => {
    const uploadFile = async () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, name);
    
      // Use a unique name for the storage reference
      const uploadTask = uploadBytesResumable(storageRef, file);
    
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    
          // Check if the images array has already reached the maximum allowed (3 images)
          if (data?.image) {
              // Replace the first image in the array with the new one
              setData((prev) => ({
                ...prev,
                image: downloadURL,
              }));
          } else {
            // Add the new image to the images array
            setData((prev) => ({
              ...prev,
              images: [...(prev.images || []), downloadURL],
            }));
          }
        }
      );
    };    
  
    file && uploadFile();
  }, [file]);  

  // console.log(data);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
  
    // Check if the input field is the email field, and ignore the change
    if (id === "email") {
      return;
    }
  
    // Check if the input field is in the main data
    const isMainDataField = inputs.find(input => input.id === id) !== undefined;
  
    // If the input field is in the main data, update the main data state
    if (isMainDataField) {
      setData(prevData => ({
        ...prevData,
        [id]: value
      }));
    }
  };
  
  const handleAdditionalDataInput = (index, key, value) => {
    setData(prevData => ({
      ...prevData,
      additionalData: prevData.additionalData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value
          };
        }
        return item;
      })
    }));
  };  

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // Assuming "data" contains the properties you want to update in the document
      const storeDocRef = doc(db, "users", uid); // Use the UID of the current user
  
      // Additional checks or operations before updating the document can be added here
  
      await updateDoc(storeDocRef, {
        ...data,
        timeStamp: serverTimestamp(),
      });
  
      // Assuming "navigate" is a function to navigate, like from react-router
      navigate(-1);
    } catch (err) {
      console.error("Error updating store details:", err);
    }
  };  

  const handleDeleteImage = (index) => {
    const fileInput = document.getElementById("file");
  
    // Trigger a click event on the file input
    fileInput.click();
  };  

  const handleDeleteImage2 = (index) => {
    // Create a file input element for selecting additional images
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    
    // Set an event listener to handle file selection for additional images
    fileInput.addEventListener('change', (event) => {
      console.log("File selected:", event.target.files[0]); // Log the selected file
      // Call handleSelectAdditionalImage with the selected file and index
      handleSelectAdditionalImage(event, index);
    });
    
    // Append the file input to the document body
    document.body.appendChild(fileInput);
  
    // Trigger a click event on the file input
    fileInput.click();
    
    // Clean up: Remove the file input from the document body after use
    fileInput.remove();
  };
  
  const handleSelectAdditionalImage = async (e, index) => {
    const selectedFile = e.target.files[0];
  
    try {
      if (selectedFile) {
        // Upload the selected image to Firebase Storage
        const storageRef = ref(storage, `${uid}/${selectedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Track upload progress if needed
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            console.error("Error uploading image:", error);
          },
          async () => {
            // Image uploaded successfully, get the download URL
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            // Update the additionalData array with the new image URL
            setData((prevData) => ({
              ...prevData,
              additionalData: prevData.additionalData.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    image: downloadURL,
                  };
                }
                return item;
              }),
            }));
          }
        );
      }
    } catch (error) {
      console.error("Error selecting additional image:", error);
    }
  };  

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get("session_id");
  
    const updatePaymentStatus = async (companyName, uid, subscriptionId) => {
      try {
        const userDocRef = doc(db, "users", uid);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
  
          if (userData?.bname === companyName) {
            await updateDoc(userDocRef, { payment: "yes", subscriptionId });
            console.log("Payment status updated for", companyName);
          } else if (userData.additionalData && userData.additionalData.length > 0) {
            const updatedAdditionalData = userData.additionalData.map((item) =>
              item?.bname === companyName
                ? { ...item, payment: "yes", subscriptionId }
                : item
            );
            await updateDoc(userDocRef, { additionalData: updatedAdditionalData });
            console.log("Payment status updated for", companyName);
          } else {
            console.log("Company not found in additional data");
          }
        } else {
          console.log("User document not found");
        }
  
        localStorage.removeItem("payment-item");
        navigate("/profile");
        window.location.reload();
      } catch (error) {
        console.error("Error updating payment status:", error);
      }
    };
  
    const handleSessionRetrieval = async () => {
      if (sessionId && currentUser?.uid) {
        const uid = currentUser.uid;
  
        try {
          const stripe = require("stripe")("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");
          const session = await stripe.checkout.sessions.retrieve(sessionId);
  
          if (session) {
            console.log("Session retrieved:", session);
            const subscriptionId = session.subscription || session.payment_intent || null;
  
            if (subscriptionId) {
              const storedItem = localStorage.getItem("payment-item");
              const parsedItem = JSON.parse(storedItem);
              const companyName = parsedItem?.bname;
  
              console.log("Company Name:", companyName);
              updatePaymentStatus(companyName, uid, subscriptionId);
            } else {
              console.log("No subscription or payment intent found in session");
            }
          } else {
            console.error("Failed to retrieve session.");
          }
        } catch (error) {
          console.error("Error retrieving session:", error);
        }
      }
    };
  
    handleSessionRetrieval();
  }, [location.search]);  

  const handleSelectImage = (e) => {
    const selectedFile = e.target.files[0];
  
    // Log information for debugging
    console.log("Selected File:", selectedFile);
  
    // Read the selected image as a data URL and update the state
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log("Reader Result:", reader.result);
      setUserImage(reader.result);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    } else {
      // If no file is selected, clear the preview
      setUserImage(null);
    }
  };  

  const handleResetPassword = async () => {
    try {
      // Trigger the password reset email
      await sendPasswordResetEmail(auth, currentUser.email);
      alert("Password reset email sent. Please check your email.");
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      alert("Failed to send password reset email. Please try again later.");
    }
  };

  const handleStripeRedirect1 = (item) => {
    // Store the payment status in localStorage
    localStorage.setItem("payment-item", JSON.stringify(item));
    // Redirect to the specified link when the button is clicked
    window.location.href = 'https://buy.stripe.com/9AQ4h29sBcVC1Q4cMN';
  };
  
  const handleStripeRedirect2 = (item) => {
    localStorage.setItem("payment-item", JSON.stringify(item));
    // Redirect to the specified link when the button is clicked
    window.location.href = 'https://buy.stripe.com/aEU8xi48h4p69iwaEG';
  };

  const handleStripeRedirect3 = (item) => {
    localStorage.setItem("payment-item", JSON.stringify(item));
    // Redirect to the specified link when the button is clicked
    window.location.href = 'https://buy.stripe.com/4gw9Bm2095ta9iwfZ1';
  };

  const gbpCountries = [
    "United Kingdom", 
    "Isle of Man",
    "Channel Islands",
    "Gibraltar",
    "Falkland Islands",
    "Saint Helena, Ascension, and Tristan da Cunha",
    "British Antarctic Territory",
    "South Georgia and the South Sandwich Islands"
  ];

  return (
    <div className="Profile">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">Profile Information</div>
        <div className="bottom">
          <div className="left">
            {data?.image ? (
              <div className="image-container">
                <img src={userImage} alt="Profile" onClick={() => handleDash(data?.bname)}/>
                <div className="image-info">
                  <button onClick={handleDeleteImage} className="delete-btn">
                    Choose a file
                  </button>
                  <div className="info">
                    Acceptable formats PNG only<br />
                    max file size 500kb and min size 70kb
                  </div>
                </div>
              </div>
            ) : (
              <p>No image available</p>
            )}
            {/* Display additional images */}
            {data?.additionalData?.map((item, index) => (
              <div key={index} className="image-container">
                <img src={item.image} alt="Profile" onClick={() => handleDash(item?.bname)}/>
                <div className="image-info">
                  <button onClick={() => handleDeleteImage2(index)} className="delete-btn">
                    Choose a file
                  </button>
                  <div className="info">
                    Acceptable formats PNG only<br />
                    max file size 500kb and min size 70kb
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="right">
            <div className="top1">Account Information</div>
            <form onSubmit={handleUpdate}>
              <div className="formInput">
                <input
                  type="file"
                  id="file"
                  onChange={handleSelectImage}
                  style={{ display: "none" }}
                />
              </div>
  
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                    value={data[input.id] || ""}
                    disabled={input.id === "email"}
                  />
                </div>
              ))}

            {/* Render additional data inputs */}
            {data?.additionalData?.map((additionalDataItem, index) => (
              <div key={`additional_${index}`}>
                {/* Render each desired field in the additional data */}
                {Object?.keys(additionalDataItem)
                  ?.filter(key => key === "bname" || key === "link")
                  ?.map((key) => (
                    <div key={`additional_${index}_${key}`} className="formInput">
                      {/* Replace key values with desired labels */}
                      {data && (
                      <label>{key === "bname" ? "Business Name" : "Business Link"}</label>
                      )}
                      <input
                        type="text"
                        value={additionalDataItem[key]}
                        onChange={(e) => handleAdditionalDataInput(index, key, e.target.value)} // Add onChange handler
                        style={{ marginTop: '15px', marginBottom: '15px' }}
                      />
                    </div>
                  ))}
              </div>
            ))}

              <button disabled={per !== null && per < 100} type="submit">
                Save Changes
              </button>
              <button type="button" onClick={handleResetPassword}>
                Reset Password
              </button>
              {[data, ...(data?.additionalData || [])].filter(item => item?.payment === "no").map((item, index) => (
                <div key={index}>
                  <div className="top2">Complete the payment here to display your brand - {item?.bname}</div>
                  <div className="brand-payment">
                    <div className="payment-options">
                      <div className="best-value">
                        <p className="best-text">Best value</p>
                      </div>
                      <div className="row-payment">
                        <div className="payment-text">
                          <p className="year-text">12 months</p>
                          <div className="money-row">
                            <p className="price">£ 20</p><p className="month-text"> /annually</p>
                          </div>
                          <p className="terms-text1"><span className="custom-color">£ 20</span> every<span className="custom-color"> 12</span> months</p>
                          <p className="terms-text2">VAT and local taxes may apply</p>
                          {userCountry && !gbpCountries.includes(userCountry) && (
                          <p className="terms-text2 custom-color1">£ 20 ≈ ${convertEuroToDollar(20)} (USD)</p>
                          )}
                        </div>
                        <button className="subscribe-button" onClick={() => handleStripeRedirect1(item)}>
                          <p className="subscribe-text">Subscribe now</p>
                        </button>
                      </div>
                    </div>
                    <div className="payment-options1">
                      <div className="row-payment">
                        <div className="payment-text">
                          <p className="year-text">1 month</p>
                          <div className="money-row">
                            <p className="price">£ 1.99</p><p className="month-text"> /monthly</p>
                          </div>
                          <p className="terms-text1"><span className="custom-color">£ 1.99</span> every month</p>
                          <p className="terms-text2">VAT and local taxes may apply</p>
                          {userCountry && !gbpCountries.includes(userCountry) && (
                          <p className="terms-text2 custom-color1">£ 1.99 ≈ ${convertEuroToDollar(2)} (USD)</p>
                          )}
                        </div>
                        <button className="subscribe-button" onClick={() => handleStripeRedirect2(item)}>
                          <p className="subscribe-text">Subscribe now</p>
                        </button>
                      </div>
                    </div>
                    <div className="payment-options1">
                      <div className="row-payment">
                        <div className="payment-text">
                          <p className="year-text">One Time</p>
                          <div className="money-row">
                            <p className="price">£ 145</p><p className="month-text"></p>
                          </div>
                          <p className="terms-text1"><span className="custom-color"></span></p>
                          <p className="terms-text2">VAT and local taxes may apply</p>
                          {userCountry && !gbpCountries.includes(userCountry) && (
                          <p className="terms-text2 custom-color1">£ 145 ≈ ${convertEuroToDollar(145)} (USD)</p>
                          )}
                        </div>
                        <button className="subscribe-button" onClick={() => handleStripeRedirect3(item)}>
                          <p className="subscribe-text">Purchase now</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default UpdateProfile;
