import "./start.scss";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SliderC from "../../components/logo-slider/slider";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { db } from "../../firebase";
import RotatingSphere from "../../components/sphere/sphere";
import { Canvas } from "react-three-fiber";
import Sidebar from 'react-sidebar';

const Start = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleSearch = () => {
    navigate("/search");
  };

  const handleChatbot = () => {
    navigate("/chatbot");
    window.scrollTo(0, 0)
  };

  const handleHome = () => {
    navigate("/start");
    window.scrollTo(0, 0)
  };

  const handleAboutUs = () => {
    navigate("/about");
    window.scrollTo(0, 0)
  };

  const handleTerms = () => {
    navigate("/terms");
    window.scrollTo(0, 0)
  };

  const handleHelp = () => {
    navigate("/help");
    window.scrollTo(0, 0)
  };

  const [users, setUsers] = useState([]);
  const [userCountry, setUserCountry] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch initial user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    // Fetch user's country based on their location
    if (userLocation) {
      fetchCountryByCoordinates(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  // const fetchCountry = () => {
  //   fetch("http://ip-api.com/json/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('Fetched country data:', data); // Log the fetched country data
  //       const countryName = data.country || "";
  //       setUserCountry(countryName);
  //     })
  //     .catch((error) => {
  //       console.error("Error getting user's country:", error);
  //     });
  // };  

  const fetchCountryByCoordinates = (latitude, longitude) => {
    // You can use reverse geocoding API here to fetch country based on latitude and longitude
    // Example: Fetch country using Google Maps Geocoding API
    const apiKey = 'AIzaSyAUlvtxGpDfZvmYpvt02uwn8EunzwgSUk8';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Extract country from the response
        const country = data.results[0].address_components.find(
          (component) => component.types.includes("country")
        );
        const countryName = country ? country.long_name : "";
        // console.log(countryName)
        setUserCountry(countryName);
      })
      .catch((error) => {
        console.error("Error getting user's country by coordinates:", error);
      });
  };

  const stripe = require('stripe')('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');

  useEffect(() => {
    const fetchDataAndCleanup = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const unsubscribeFunctions = []; // Array to store unsubscribe functions
        querySnapshot.forEach(async (docSnapshot) => {
          const userData = docSnapshot.data();
          const { subscriptionId, payment } = userData;
          // if (subscriptionId) {
          //   try {
          //     const subscription = await stripe.subscriptions.retrieve(subscriptionId) ||  await stripe.paymentIntents.retrieve(subscriptionId);
          //     // console.log("subscription status",subscription.status)
          //     // console.log("Subscription end date:", new Date(subscription.current_period_end * 1000).toDateString());
          //     if (subscription.status !== 'active' || subscription.status !== 'succeeded') {
          //       const userRef = doc(db, 'users', docSnapshot.id);
          //       await updateDoc(userRef, { payment: 'no' });
          //       // console.log(`User ${docSnapshot.id} is not subscribed anymore. Payment status updated to 'no'.`);
          //     }
          //     else {
          //       const userRef = doc(db, 'users', docSnapshot.id);
          //       await updateDoc(userRef, { payment: 'yes' });
          //       // console.log(`User ${docSnapshot.id} is subscribed. Payment status updated to 'yes'.`);
          //     }
          //   } catch (error) {
          //     console.error('Error checking subscription status:', error);
          //   }
          if (subscriptionId) {
            try {
              // Try retrieving as subscription or paymentIntent
              const subscription =
                (await stripe.subscriptions.retrieve(subscriptionId).catch(() => null)) || 
                (await stripe.paymentIntents.retrieve(subscriptionId).catch(() => null));
          
              if (!subscription) {
                console.error(`No valid subscription or paymentIntent found for ID: ${subscriptionId}`);
                return;
              }
          
              // Check the status
              if (subscription.status !== 'active' && subscription.status !== 'succeeded') {
                const userRef = doc(db, 'users', docSnapshot.id);
                await updateDoc(userRef, { payment: 'no' });
                // console.log(`User ${docSnapshot.id} is not subscribed or payment not succeeded. Payment status updated to 'no'.`);
              } else {
                const userRef = doc(db, 'users', docSnapshot.id);
                await updateDoc(userRef, { payment: 'yes' });
                // console.log(`User ${docSnapshot.id} has active subscription or succeeded payment. Payment status updated to 'yes'.`);
              }
            } catch (error) {
              console.error('Error checking subscription or paymentIntent status:', error);
            }          
          } else {
            // If there's no subscription, update payment status to 'no'
            const userRef = doc(db, 'users', docSnapshot.id);
            await updateDoc(userRef, { payment: 'no' });
            // console.log(`User ${docSnapshot.id} doesn't have a subscription.`);
          }

          // console.log(userData); 

          // console.log("additioanal data",userData.additionalData)

          if (userData.additionalData && Array.isArray(userData.additionalData)) {
            userData.additionalData.forEach(async (data) => {
            // Extract necessary fields from additional data
            const { subscriptionId, payment } = data; 

            // console.log("sub id",subscriptionId)
          
            if (subscriptionId) {
              try {
                // const subscription = await stripe.subscriptions.retrieve(subscriptionId) ||  await stripe.paymentIntents.retrieve(subscriptionId);
                // Try retrieving as subscription or paymentIntent
                const subscription =
                (await stripe.subscriptions.retrieve(subscriptionId).catch(() => null)) || 
                (await stripe.paymentIntents.retrieve(subscriptionId).catch(() => null));

                if (!subscription) {
                  console.error(`No valid subscription or paymentIntent found for ID: ${subscriptionId}`);
                  return;
                }
                //   console.log("subscription status add",subscription.status)
                // console.log("Subscription add end date:", new Date(subscription.current_period_end * 1000).toDateString());
                // if (subscription.status !== 'active' || subscription.status !== 'succeeded') {
                  if (subscription.status !== 'active' && subscription.status !== 'succeeded') {
                  const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('subscriptionId', '==', subscriptionId)));
                  userQuerySnapshot.forEach(async (docSnapshot) => {
                    const userRef = doc(db, 'users', docSnapshot.id);
                    await updateDoc(userRef, { payment: 'no' });
                    // console.log(`User ${docSnapshot.id} in additional data is not subscribed anymore. Payment status updated to 'no'.`);
                  });
                }
                else {
                  const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('subscriptionId', '==', subscriptionId)));
                  userQuerySnapshot.forEach(async (docSnapshot) => {
                    const userRef = doc(db, 'users', docSnapshot.id);
                    await updateDoc(userRef, { payment: 'yes' });
                    // console.log(`User ${docSnapshot.id} in additional data is subscribed. Payment status updated to 'yes'.`);
                  });
                }
              } catch (error) {
                console.error('Error checking subscription status:', error);
              }
            } else {
              // If there's no subscription, update payment status to 'no'
              const userQuerySnapshot = await getDocs(query(collection(db, 'users'), where('subscriptionId', '==', subscriptionId)));
                  userQuerySnapshot.forEach(async (docSnapshot) => {
                    const userRef = doc(db, 'users', docSnapshot.id);
                    await updateDoc(userRef, { payment: 'no' });
                    // console.log(`User ${docSnapshot.id} in additional data is not subscribed anymore. Payment status updated to 'no'.`);
                  });
              // console.log(`User ${docSnapshot.id} doesn't have a subscription.`);
            }

          }) }
          else {
            // console.error('additionalData is either undefined or not an array.');
          }
        });
        // Add unsubscribe functions to the array
        // (Currently, there are no subscriptions to unsubscribe, so this array is empty)
        // If you add subscription listeners or similar, add the cleanup logic here
        // unsubscribeFunctions.push(unsubscribeFunction);
  
        // Return a cleanup function
        return () => {
          // Call all unsubscribe functions for cleanup if needed
          unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
      } catch (error) {
        console.error('Error retrieving user data:', error);
      }
    };
  
    // Call the fetchDataAndCleanup function immediately
    fetchDataAndCleanup();
  
    // Since there are no dependencies, the effect runs once after the component mounts
    // and the cleanup function returned will be called when the component unmounts.
  }, []); // Empty dependency array signifies this effect should only run once after mounting  

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      const usersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Filter users based on the user's country and payment status
      const filteredUsers = usersData.filter(user => {
        const normalizedUserCountry = user.country?.toLowerCase().replace(/\s/g, '');
        const normalizedAPIUserCountry = userCountry?.toLowerCase().replace(/\s/g, '');
  
        // Check if the user has no specified country and payment status is 'yes'
        if (!normalizedAPIUserCountry && user.payment === 'yes') {
          return true; // Include the user in the filtered list
        }
  
        // Check if the user's country matches the API user's country and payment status is 'yes'
        const mainUserDataMatches = normalizedUserCountry === normalizedAPIUserCountry && user.payment === 'yes';
  
        // Check if additionalData exists and iterate over it
        if (user.additionalData) {
          const additionalDataMatches = user.additionalData.some(data => {
            const normalizedDataCountry = data.country?.toLowerCase().replace(/\s/g, '');
            return normalizedDataCountry === normalizedAPIUserCountry && data.payment === 'yes';
          });
          return mainUserDataMatches || additionalDataMatches;
        }
  
        return mainUserDataMatches;
      });
  
      // console.log('Filtered Users:', filteredUsers); // Log the filtered users
  
      setUsers(filteredUsers);
    });
  
    return () => unsubscribe();
  }, [userCountry]);  

  const handleRedirect = async (user) => {
    if (!user || !user.bname) {
        console.error('User data is missing or invalid.');
        return;
    }

    try {
        const userSnapshot = await getDocs(collection(db, 'users'));

        userSnapshot.forEach(async (doc) => {
            const userData = doc.data();

            // If the document has additionalData field, update its counters
            if (userData.additionalData) {
                userData.additionalData.forEach((item) => {
                    if (item.bname === user.bname) {
                        // If counter1 doesn't exist for the item, initialize it to 0
                        if (!item.counter1) {
                            item.counter1 = 0;
                        }

                        // If counter2 doesn't exist for the item, initialize it to 0
                        if (!item.counter2) {
                            item.counter2 = 0;
                        }

                        // Increment counter1 for the item and reset it if it's a new month
                        const today = new Date();
                        const currentMonth = today.getMonth();
                        if (item.lastUpdatedMonth !== currentMonth) {
                            item.counter2 = item.counter1;
                            item.counter1 = 0;
                            item.lastUpdatedMonth = currentMonth; // Update lastUpdatedMonth to the current month
                        }
                        item.counter1 += 1;
                    }
                });
            }

            // If the document itself matches the user's bname, update its counters
            if (userData.bname === user.bname) {
                // If counter1 doesn't exist for the item, initialize it to 0
                if (!userData.counter1) {
                    userData.counter1 = 0;
                }

                // If counter2 doesn't exist for the item, initialize it to 0
                if (!userData.counter2) {
                    userData.counter2 = 0;
                }

                // Increment counter1 for the item and reset it if it's a new month
                const today = new Date();
                const currentMonth = today.getMonth();
                if (userData.lastUpdatedMonth !== currentMonth) {
                    userData.counter2 = userData.counter1;
                    userData.counter1 = 0;
                    userData.lastUpdatedMonth = currentMonth; // Update lastUpdatedMonth to the current month
                }
                userData.counter1 += 1;
            }

            // Update the document with the updated data
            await updateDoc(doc.ref, userData);
        });

        console.log('Counters updated successfully!');
         // Check if user.link starts with https://, if not prepend https://
         const url = user.link.startsWith('https://') ? user.link : `https://${user.link}`;
         window.location.href = url;
    } catch (error) {
        console.error('Error updating counters:', error);
    }
};

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const sidebarContent = (
    <div className="sidebar">
        <button type="submit" className="home-text" onClick={handleHome}>Home</button>
        <button type="submit" className="home-text" onClick={handleChatbot}>Chat bot</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        <button type="submit" className="home-text" onClick={handleAboutUs}>About Us</button>
        <button type="submit" className="home-text" onClick={handleHelp}>Help</button>
    </div>
  );

  const handleWorld = () => {
    // Scroll to the element with the id "scroll-target" with smooth behavior
    document.getElementById("scroll-target").scrollIntoView({ behavior: "smooth" });
  };  

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling behavior
    });
  };

  // Function to check if scroll position is at the bottom of the page and show/hide the button
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY || window.pageYOffset;
    const bodyHeight = document.body.offsetHeight;

    if (scrollY > 0.92 * (bodyHeight - windowHeight)) { // Show button when user is near the bottom (adjust percentage as needed)
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Effect hook to add/remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <div className="header-container">
      <div className="logo-img-container" onClick={handleHome}>
        <img src="/images/logo.png" alt="logo" className="logo"/>
        <div className="logo-name">BrandForgeX</div>
      </div>
      <div className="search">
          <input
            type="text"
            placeholder="Search a Business"
            className="search-box"
            onClick={handleSearch}
          />
          <div className="search-icon-container"  onClick={handleSearch}>
          <SearchOutlinedIcon className="search-icon"/>
          </div>
            <button onClick={toggleSidebar} className="sidebar-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="15" viewBox="0 0 22 15" fill="none">
              <path d="M0.9375 14.0625C0.419733 14.0625 0 13.6428 0 13.125V13.125C0 12.6072 0.419733 12.1875 0.9375 12.1875H20.9375C21.4553 12.1875 21.875 12.6072 21.875 13.125V13.125C21.875 13.6428 21.4553 14.0625 20.9375 14.0625H0.9375ZM0.9375 7.96875C0.419733 7.96875 0 7.54902 0 7.03125V7.03125C0 6.51348 0.419733 6.09375 0.9375 6.09375H20.9375C21.4553 6.09375 21.875 6.51348 21.875 7.03125V7.03125C21.875 7.54902 21.4553 7.96875 20.9375 7.96875H0.9375ZM0.9375 1.875C0.419733 1.875 0 1.45527 0 0.9375V0.9375C0 0.419733 0.419733 0 0.9375 0H20.9375C21.4553 0 21.875 0.419733 21.875 0.9375V0.9375C21.875 1.45527 21.4553 1.875 20.9375 1.875H0.9375Z" fill="white"/>
            </svg>
            </button>
        </div>
        <Sidebar
          sidebar={sidebarContent}
          open={isSidebarOpen}
          onSetOpen={toggleSidebar}
          styles={{ sidebar: { background: '#0A112D', width: '200px' } }}
        >
          <div></div>
        </Sidebar>
      <div className="login">
        <div className="button-container">
        <button type="submit" className="home-text1" onClick={handleHome}>Home</button>  
        <button type="submit" className="home-text2" onClick={handleChatbot}>Chatbot</button>  
        <button type="submit" className="button1" onClick={handleLogin}>Login</button>
        <button type="submit" className="button2" onClick={handleSignUp}>Signup</button>
        </div>
      </div>
    </div>
    <div className="start-line"/>
<div className="center-container">
  <div className="center-container1">
  <div className="center-text1">Aiming for 1M users by December 2024</div>
  </div>
  <div className="center-text2">Brand Marketing</div>
  <div className="center-text3">Optimized Reach.</div>
  <div className="center-text4">
  {/* At Brandforgex, our mission is
to empower businesses of all
sizes to elevate their
  <br className="verthe-break"/>
  marketing strategies and
drive sales through innovative
digital solutions */}
Connect with your target audience effectively
</div>
<div className="center-container2"  onClick={handleSignUp}>
<div className="center-text5">Get Started</div>
</div>
</div>

<div className="logoSlide">
{users.length !== 0 && (
  <SliderC />
  )}
</div>

<div className="animated" id="scroll-target">
<div className="animated-text-container">
  <div className="an-text-1">Mission</div>
  <div className="blue-line"></div>
  <div className="an-text-2">At Brandforgex, our mission is
to empower businesses of all
sizes to elevate their
marketing strategies and
drive sales through innovative
digital solutions.

<br/>

<p style={{ marginTop: '20px' }}>We strive to
provide a user-friendly
platform that enables
companies to effectively
communicate their brand
identity and connect with
their target audience.</p>

</div>
</div>
{/* <div className="bg-animated"></div> */}
<div className="animated-world-container">
{/* <img src="/images/earth.svg" alt="logo" className="world"/> */}
<div className="world">
<Canvas>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <RotatingSphere />
</Canvas>
</div>
<div className="world-shadow"></div>
</div>
</div>
<div className="business-text-container">
  <div className="bs-text-1">Businesses</div>
  <div className="bs-text-2"></div>
</div>
{users.length === 0 ? (
          <div className="no-business">No businesses available in your country</div>
        ) : (
          <>
<div className="business">
<div className="business1">
<div className="business-world-container">
<div className="business-world1-container">
  {/* Map through the sliced users and render all user data together */}
  {users.slice(0, 8).map((user, userIndex) => (
    <React.Fragment key={userIndex}>
      {/* Display user information */}
      <div className="business-logo-container">
        <img src={user.image} alt={`logo-${userIndex}`} className="bs-logo" />
        <div className="bs-name">{user.bname}</div>
        <button className="bs-button" onClick={() => handleRedirect(user)}>
          Visit
        </button>
      </div>

      {/* Display additional data if available */}
      {user.additionalData && user.additionalData.map((data, dataIndex) => (
        data.payment === "yes" && (
        <div key={dataIndex} className="business-logo-container">
          <img src={data.image} alt={`additional-logo-${userIndex}-${dataIndex}`} className="bs-logo" />
          <div className="bs-name">{data.bname}</div>
          <button className="bs-button" onClick={() => handleRedirect(data)}>
            Visit
          </button>
        </div>
          )
      ))}
    </React.Fragment>
  ))}
</div>
</div>
</div>
</div>
<div className="business2">
<div className="business1">
<div className="business-world-container">
<div className="business-world1-container">
{users.slice(0, 6).map((user, userIndex) => (
  <React.Fragment key={userIndex}>
  <div className="business-world1-container">
    {/* Display user information */}
    <div className="business-logo-container">
      <img src={user.image} alt={`logo-${userIndex}`} className="bs-logo" />
      <div className="bs-name">{user.bname}</div>
      <button className="bs-button" onClick={() => handleRedirect(user)}>
        Visit
      </button>
    </div>

    {/* Display additional data if available */}
    {/* {user.additionalData && user.additionalData.slice(0, 3).map((data, dataIndex) => (
      <div key={dataIndex} className="business-logo-container">
        <img src={data.image} alt={`additional-logo-${userIndex}-${dataIndex}`} className="bs-logo" />
        <div className="bs-name">{data.bname}</div>
        <button className="bs-button" onClick={() => handleRedirect(data)}>
          Visit
        </button>
      </div>
    ))} */}
  </div>
  </React.Fragment>
))}
</div>
</div>
</div>
</div>
<div className="business2">
<div className="business1">
<div className="business-world-container1">
<div className="business-world1-container">
{users.slice(6, 12).map((user, userIndex) => (
  <React.Fragment key={userIndex}>
  <div className="business-world1-container">
    {/* Display user information */}
    <div className="business-logo-container">
      <img src={user.image} alt={`logo-${userIndex}`} className="bs-logo" />
      <div className="bs-name">{user.bname}</div>
      <button className="bs-button" onClick={() => handleRedirect(user)}>
        Visit
      </button>
    </div>

    {/* Display additional data if available */}
    {/* {user.additionalData && user.additionalData.slice(0, 3).map((data, dataIndex) => (
      <div key={dataIndex} className="business-logo-container">
        <img src={data.image} alt={`additional-logo-${userIndex}-${dataIndex}`} className="bs-logo" />
        <div className="bs-name">{data.bname}</div>
        <button className="bs-button" onClick={() => handleRedirect(data)}>
          Visit
        </button>
      </div>
    ))} */}
  </div>
  </React.Fragment>
))}
</div>
</div>
</div>
</div>
<div className="see-container">
<button className="bt-see" onClick={handleSearch}>See all</button>
</div>
</>
        )}
{/* <div className="price">
<div className="price1">
<div className="price-text-container">
  <div className="pr-text-1">Pricing</div>
  <div className="pr-text-2">One fixed price to get<br /> your business visible</div>
  <div className="pr-text-3">your custom on-demand team for only a single payment</div>
</div>
</div> */}
{/* <div className="price-container">
<img src="/images/cube.svg" alt="logo" className="cube1"/>
<img src="/images/cube.svg" alt="logo" className="cube2"/>
  <div className="price-info-container1">
    <div className="basic-text">Starter Plan</div>
    <div className="price-text">(£ 1.99/Monthly)</div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Get redirected customers via platform</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Logo visualisation on home page</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Business analytics report on users</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Free recommended consultancy</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Free email newsletters on how to improve your business</div>
    </div> */}
    {/* 
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Basic social listening tools for monitoring brand mentions</div>
    </div> */}
    {/* <div className="price-button-container" onClick={handleSignUp}>
      <div className="bt-price-text">Get Started</div>
      <img src="/images/vector.svg" alt="logo" className="price-bt-logo"/>
    </div>
  </div>
  <div className="price-info-container2">
    <div className="basic-text">Standard Plan</div>
    <div className="price-text">(£ 20.00/Annually)</div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Get redirected customers via platform</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Logo visualisation on home page</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Business analytics report on users</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Free recommended consultancy</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Free email newsletters on how to improve your business</div>
    </div>
    <div className="price-button-container" style={{background: '#1A3EBD'}} onClick={handleSignUp}>
      <div className="bt-price-text">Get Started</div>
      <img src="/images/vector.svg" alt="logo" className="price-bt-logo"/>
    </div>
  </div> */}
  {/* <div className="price-info-container3">
    <div className="basic-text">Professional Plan</div>
    <div className="price-text">($9.99/month)</div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Create, manage, and track unlimited marketing campaigns</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Define unlimited target audience segments</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Set campaign goals and objectives</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Connect and manage up to 10 social media accounts</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Schedule and publish unlimited posts across platforms</div>
    </div>
    <div className="price-info">
    <img src="/images/checkbox.svg" alt="logo" className="price-logo"/>
    <div className="info-text">Advanced social listening tools for monitoring brand mentions <br /> and Campaign performance reporting</div>
    </div>
    <div className="price-button-container" onClick={handleSignUp}>
      <div className="bt-price-text">Get Started</div>
      <img src="/images/vector.svg" alt="logo" className="price-bt-logo"/>
    </div>
  </div> */}
{/* </div>
</div> */}
<div className="rating">
<div className="rating1">
<div className="rating-text-container">
  <div className="rt-text-1">Aiming for 1M users by December 2024</div>
  <div className="rt-text-2">Testimonials from Brandforgex Users</div>
</div>
<div className="rating-world-container">
  <div className="rating-container1">
  <div className="review-main-contianer">
  <div className="review-container">
    <div className="review-text">"Brandforgex has been a game-changer for our marketing efforts. With its user-friendly interface and seamless integration capabilities, we've been able to showcase our brand to a wider audience and drive traffic to our website. Highly recommended!"</div>
    </div>
    <div className="reviewer-name"></div>
    <div className="review-name">Sarah Johnson</div>
    <img src="/images/stars.svg" alt="star" className="review-logo"/>
</div>
<div className="review-main-contianer">
  <div className="review-container">
    <div className="review-text">"As a small business owner, I needed a cost-effective solution to improve our online presence. Brandforgex exceeded my expectations. It's easy to use, affordable, and has helped us connect with more customers than ever before. Thanks, Brandforgex!"</div>
    </div>
    <div className="reviewer-name"></div>
    <div className="review-name">John Smith</div>
    <img src="/images/stars.svg" alt="star" className="review-logo"/>
</div>
</div>
<div className="review-main-contianer-1">
  <div className="review-container">
    <div className="review-text">"Brandforgex has become an essential part of our marketing toolkit. The platform's robust features and responsive customer support have helped us streamline our marketing efforts and achieve measurable results. I can't imagine managing our brand without it."</div>
    </div>
    <div className="reviewer-name"></div>
    <div className="review-name">Emily Nguyen</div>
    <img src="/images/stars.svg" alt="star" className="review-logo"/>
</div>
<div className="rating-container1">
  <div className="review-main-contianer">
  <div className="review-container">
    <div className="review-text">"I was skeptical at first, but Brandforgex has truly delivered. It's helped us elevate our brand and stand out in a crowded marketplace. The ability to showcase our logo and integrate our website link has been invaluable. Thank you, Brandforgex, for helping us grow our business!"</div>
    </div>
    <div className="reviewer-name"></div>
    <div className="review-name">Michael Patel, Founder of Patel & Co. Consulting</div>
    <img src="/images/stars.svg" alt="star" className="review-logo"/>
</div>
<div className="review-main-contianer">
  <div className="review-container">
    <div className="review-text">"Brandforgex has simplified our marketing processes and allowed us to focus on what matters most—connecting with our customers. The platform's intuitive interface and powerful features have made it easy for us to manage our brand and drive engagement. Highly recommended for businesses looking to make a meaningful impact online."</div>
    </div>
    <div className="reviewer-name"></div>
    <div className="review-name">Jessica Thompson</div>
    <img src="/images/stars.svg" alt="star" className="review-logo"/>
</div>
</div>
</div>
</div>
</div>
<div className={`top-up1`} onClick={handleChatbot}>
      <img src="/images/chatbot.png" alt="chatbot" aria-label="img" className="chatbot-img"/>
</div>
<div className={`top-up ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
      <span className="arrow" role="img" aria-label="arrow">↑</span>
    </div>
<div className="footer">
<div className="footer1">
<div className="ft-text-container">
<div className="footer-img-container">
        <img src="/images/logo.png" alt="logo" className="footer-logo"  onClick={handleScrollToTop} style={{ cursor: 'pointer' }}/>
<div className="footer-logo-name"  onClick={handleScrollToTop} style={{ cursor: 'pointer' }}>BrandForgeX</div>
</div>
<div className="copy-text">Copyright © 2024</div>
{/* <div className="copy-text">SC798823 - Incorporated on 13 February 2024</div> */}
<div className="copy-text">55 Kirkton Avenue, Glasgow, Scotland</div>
<div className="copy-text" style={{ cursor: 'pointer' }} onClick={handleTerms}>By Creating an Account, it means you agree to our <span className="underline">Privacy Policy</span> and <span className="underline">Terms of Service</span></div>
</div>
<div
  style={{
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.20)',
    width: '90%',
    margin: 'auto',
  }}
/>
<div className="footer-text-container">
<div className="footer-help"  onClick={handleScrollToTop}>Home </div>
<div className="footer-link-container">
<div className="footer-help"  onClick={handleAboutUs}>About Us</div>
<div className="footer-help"  onClick={handleHelp}>Help</div>
</div>
</div>
</div>
</div>
</div>
  );
};

export default Start;
